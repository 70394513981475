var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._v(_vm._s(_vm.payment_list.payment_name) + " $" + _vm._s(_vm.payment_list.money)), _vm.payment_list.payment_status == 'paid' ? _c('span', {
    staticClass: "badge badge-success badge-pill"
  }, [_vm._v(_vm._s(_vm.$t('paid')))]) : _vm.payment_list.payment_status == 'unpaid' ? _c('span', {
    staticClass: "badge badge-danger badge-pill"
  }, [_vm._v(_vm._s(_vm.$t('unpaid')))]) : _vm.payment_list.payment_status == 'refund' ? _c('span', {
    staticClass: "badge badge-primary badge-pill"
  }, [_vm._v(_vm._s(_vm.$t('refund')))]) : _vm._e(), _c('br')]);

}
var staticRenderFns = []

export { render, staticRenderFns }