export default {
  messages: {
    tw: {
      transfer_session: '轉移堂數',
      transfer_term: '轉移期間',
      original_contract_period: '依照原合約起訖',
      session: '堂',
    },
    en: {
      transfer_session: 'Transfer',
      transfer_term: 'Term',
      original_contract_period: 'Original Contract Period',
      session: 'Session',
    },
  },
};
