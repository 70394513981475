<template lang="pug">
div
  | {{ $t('spgateway_credit_card_binding_eduction') }} {{ $t(type) }} ${{ dollar }}{{ $t('dollar') }}
  span.badge.badge-success.badge-pill(v-if="payment_status == 'paid'") {{ $t('paid') }}
  span.badge.badge-danger.badge-pill(v-else-if="payment_status == 'unpaid'") {{ $t('unpaid') }}

</template>
<script type="text/javascript">
import i18n from './../../lang.js';
export default {
  name: 'FeePaidStatus',

  components: {},

  props: {
    // eslint-disable-next-line
    payment_status: {
      type: String,
      default: function() {
        return {};
      },
    },

    type: {
      type: String,
      default: function() {
        return '';
      },
    },

    dollar: {
      type: Number,
      default: function() {
        return 0;
      },
    },
  },

  i18n,

  methods: {},
};
</script>
