<template lang="pug">
  .wuwow-card
    .wuwow-card-body
      .skeleton-box
        b-skeleton.mt-3.display-center(width="30%")
        div.mt-2(v-for="index in 20" :key="`skeleton-${index}`")
          hr
          b-skeleton.mt-3.display-center(width="50%")

</template>

<script type="text/javascript">

export default {
  name: 'ContractsSkeleton',
};
</script>
<style scoped>
.display-center{
  margin: 0 auto;
  text-align: center;
}
.skeleton-box{
  width: 80%;
}
</style>
