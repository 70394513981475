<template lang="pug">
  b-modal#vip_suspend_record_modal(size='lg' effect='fade' :ok-title="$t('confirm')" ok-only :title="$t('contract.share_detailed_info_list')")
    div(v-if="contractInfo && contractInfo.vip_suspensions")
      div(v-for="(detail, detail_index) in contractInfo.vip_suspensions" :key="detail_index")
        tr.row.col-lg-12.mb-1
          th.col-lg-3 {{ $t('apply') }}{{ $t('day') }}
          td.col-lg-3 {{ detail.created_at }}
        tr.row.col-lg-12.mb-1
          th.col-lg-3
            | {{ $t('contract.suspend_deadline') }}({{ $t('month') }})
          td.col-lg-3 {{ detail.month_amount }} {{ $t('month') }}
          th.col-lg-3
            | {{ $t('contract.suspend_deadline') }}({{ $t('day') }})
          td.col-lg-3 {{ detail.day_amount }} {{ $t('contract.day') }}
        tr.row.col-lg-12.mb-1
          th.col-lg-3 {{ $t('contract.start_date_suspension_period') }}
          td.col-lg-3 {{ detail.suspension_start }}
          th.col-lg-3 {{ $t('contract.end_date_suspension_period') }}
          td.col-lg-3 {{ detail.suspension_end }}
        hr
      tr.row.col-lg-12
        th.col-lg-12.mb-3
          h3 {{ $t('precautions') }}:
        th.col-lg-12 {{ $t('suspend_record_notice') }}.mb-4
        th.col-lg-12 {{ $t('contact_wuwow') }}

</template>

<script type="text/javascript">
import i18n from '../lang';
export default {
  name: 'VipSuspendRecordModal',

  components: {},

  props: {
    // eslint-disable-next-line
    contractInfo: {
      type: Object,
      default: () => {
        return {
          product_name: '查無方案',
        };
      },
    },
  },

  i18n,

  data() {
    return {};
  },

  computed: {},

  watch: {},

  created() {},

  methods: {
  },
};
</script>
