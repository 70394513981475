<template>
  <div>
    <!-- 如果role=47時 -->
    <!-- <template>
      <div class="are-no-contract" v-html="academy_statement_content.title" />
      <p class="txt-statement-title">
        {{ product_name }} {{ $t('class.class_statement') }}
      </p>

      <div class="are-class-statement">
        <div
          v-for="content in academy_statement_content.content"
          :key="content.title"
          class="row"
        >
          <div class="col-12 col-md-2 txt-statement-head">{{ content.title }}：</div>
          <div class="col-12 col-md-10 txt-statement-content">
            <div v-html="content.content" />
          </div>
        </div>
      </div>
    </template> -->

    <template>
      <div class="are-no-contract" v-html="vip_statement_content.title" />
      <p class="txt-statement-title mb-2">
        {{ product_name }} {{ $t('class.class_statement') }}
      </p>
      <p class="text-center mb-5 font-weight-bold">
        （ {{ $t('not_applicable') }} Wushare {{ $t('vip.vip') }}）
      </p>
      <!-- 2023/6/17 前舊合約版本排版 -->
      <!-- <div class="are-class-statement">
        <div
          v-for="content in vip_statement_content.content"
          :key="content.title"
          class="row"
        >
          <div class="col-12 col-md-2 txt-statement-head">{{ content.title }}：</div>
          <div class="col-12 col-md-10 txt-statement-content">
            <div v-html="content.content" />
          </div>
        </div>
      </div> -->

      <!-- 2023/6/17 後新版本排版 -->
      <div class="are-class-statement">
        <div
          v-for="content in vip_statement_content.content"
          :key="content.title"
        >
          <p class="txt-statement-head">{{ content.title }}：</p>
          <div class="txt-statement-content">
            <div v-html="content.content" />
          </div>
        </div>
      </div>

      <!-- 使用者無合約，但有增補協議歷程時，須在無合約內容中顯示歷程資料 -->
      <div v-if="vipAdditionTermRecord.vipContractAdditionTermRecords && vipAdditionTermRecord.vipContractAdditionTermRecords.length > 0" class="mt-4 d-flex justify-content-center">
        <button v-b-modal.supplemental_agreement_history_modal type="button" class="btn btn-primary mx-auto">增補協議歷程</button>
      </div>
    </template>
    <supplemental-agreement-history />
  </div>
</template>

<script type="text/javascript">
import storage from '@/utils/localStorage';
import companyConstant from '@/constants/company';
import i18n from '@/components/vip/contracts/noContract/lang.js';
import SupplementalAgreementHistory from '@/components/modal/contracts/supplementalAgreementHistory';
import { mapState, mapActions } from 'vuex';
import lioshutanApi from '@/api';

export default {
  name: 'VipNoContractInfo',

  components: {
    SupplementalAgreementHistory,
  },

  i18n,

  props: {
    newRule: {
      type: Boolean,
      default: () => (false),
    },
  },

  data() {
    return {
      product_name: companyConstant.INFO.PRODUCT_NAME,

      academy_statement_content: {},

      vip_statement_content: {},
    };
  },

  computed: {
    ...mapState('supplementaryAgreement', [
      'confirmStatusInfo',
      'vipAdditionTermRecord',
    ]),
  },

  watch: {},

  created() {
    this.getContractTranslateContent();
    this.getCheckStatus();
    this.getRecordInfo();
    this.hasContract();
  },

  methods: {
    ...mapActions('supplementaryAgreement', [
      'getCheckAdditionTerm',
      'getVipAdditionTermRecord',
    ]),
    async getRecordInfo(){
      await this.getVipAdditionTermRecord();
      console.log(this.vipAdditionTermRecord.vipContractAdditionTermRecords.length);
    },
    async getCheckStatus(){
      await this.getCheckAdditionTerm();
      // 將confirmStatusInfo.vipProductId的值存起來，判斷合約是否顯示歷程
      this.vipProductId = this.confirmStatusInfo.vipProductId;
    },
    // 找到對應的方案顯示協議歷程
    findSameVipProductId(item) {
      return item.learning_time && item.learning_time.some((productItem) => productItem.vip_product_id === this.vipProductId);
    },
    async hasContract() {
      /**
       * 1. 若回傳的"contract_type": "receive", 則為被轉讓者，需要在合約下方顯示協議歷程
       * 2. 若回傳owner，則照原本流程走( findSameVipProductId )，判斷方案id是否符合增補協議綁定的方案id
       */
      const result = await lioshutanApi.vip.getContractIdentity();
      this.contractType = result.data.data.contract_type;
    },
    getContractTranslateContent() {
      let contract_translate_content = {};
      const current_lang = storage.language;
      if (this.newRule) {
        contract_translate_content = require(`./contractLang/${current_lang}ClassStatement_new.json`);
      } else {
        contract_translate_content = require(`./contractLang/${current_lang}ClassStatement.json`);
      }

      if (contract_translate_content) {
        this.academy_statement_content = {
          title: contract_translate_content.academy_statement.contract_title,
          content: [
            {
              title: this.$t('class.class_time'),
              content: contract_translate_content.academy_statement.class_time,
            },
            {
              title: this.$t('class.book') + this.$t('way'),
              content: contract_translate_content.academy_statement.booking_method,
            },
            {
              title: this.$t('class.cancel_booked'),
              content: contract_translate_content.academy_statement.cancel_class,
            },
            {
              title: this.$t('service_content'),
              content: contract_translate_content.academy_statement.service_content,
            },
            {
              title: this.$t('class.classroom_norms'),
              content: contract_translate_content.academy_statement.classroom_norms,
            },
          ],
        };

        this.vip_statement_content = {
          title: contract_translate_content.vip_statement.contract_title,
          // TODO 服務內容 網址與信箱先寫死與後端統整時在一次統一 By Asa
          content: [
            {
              title: this.$t('serviceContent'),
              content: contract_translate_content.vip_statement.serviceContent,
            },
            {
              title: this.$t('classType'),
              content: contract_translate_content.vip_statement.classType,
            },
            {
              title: this.$t('bookAndCancel'),
              content: contract_translate_content.vip_statement.bookAndCancel,
            },
            {
              title: this.$t('classRule'),
              content: contract_translate_content.vip_statement.classRule,
            },
            {
              title: this.$t('equipment'),
              content: contract_translate_content.vip_statement.equipment,
            },
          ],
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
