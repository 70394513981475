var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "vip_suspend_record_modal",
      "size": "lg",
      "effect": "fade",
      "ok-title": _vm.$t('confirm'),
      "ok-only": "",
      "title": _vm.$t('contract.share_detailed_info_list')
    }
  }, [_vm.contractInfo && _vm.contractInfo.vip_suspensions ? _c('div', [_vm._l(_vm.contractInfo.vip_suspensions, function (detail, detail_index) {
    return _c('div', {
      key: detail_index
    }, [_c('tr', {
      staticClass: "row col-lg-12 mb-1"
    }, [_c('th', {
      staticClass: "col-lg-3"
    }, [_vm._v(_vm._s(_vm.$t('apply')) + _vm._s(_vm.$t('day')))]), _c('td', {
      staticClass: "col-lg-3"
    }, [_vm._v(_vm._s(detail.created_at))])]), _c('tr', {
      staticClass: "row col-lg-12 mb-1"
    }, [_c('th', {
      staticClass: "col-lg-3"
    }, [_vm._v(_vm._s(_vm.$t('contract.suspend_deadline')) + "(" + _vm._s(_vm.$t('month')) + ")")]), _c('td', {
      staticClass: "col-lg-3"
    }, [_vm._v(_vm._s(detail.month_amount) + " " + _vm._s(_vm.$t('month')))]), _c('th', {
      staticClass: "col-lg-3"
    }, [_vm._v(_vm._s(_vm.$t('contract.suspend_deadline')) + "(" + _vm._s(_vm.$t('day')) + ")")]), _c('td', {
      staticClass: "col-lg-3"
    }, [_vm._v(_vm._s(detail.day_amount) + " " + _vm._s(_vm.$t('contract.day')))])]), _c('tr', {
      staticClass: "row col-lg-12 mb-1"
    }, [_c('th', {
      staticClass: "col-lg-3"
    }, [_vm._v(_vm._s(_vm.$t('contract.start_date_suspension_period')))]), _c('td', {
      staticClass: "col-lg-3"
    }, [_vm._v(_vm._s(detail.suspension_start))]), _c('th', {
      staticClass: "col-lg-3"
    }, [_vm._v(_vm._s(_vm.$t('contract.end_date_suspension_period')))]), _c('td', {
      staticClass: "col-lg-3"
    }, [_vm._v(_vm._s(detail.suspension_end))])]), _c('hr')]);
  }), _c('tr', {
    staticClass: "row col-lg-12"
  }, [_c('th', {
    staticClass: "col-lg-12 mb-3"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t('precautions')) + ":")])]), _c('th', {
    staticClass: "col-lg-12"
  }, [_vm._v(_vm._s(_vm.$t('suspend_record_notice')) + ".mb-4")]), _c('th', {
    staticClass: "col-lg-12"
  }, [_vm._v(_vm._s(_vm.$t('contact_wuwow')))])])], 2) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }