<template lang="pug">
skeleton(:loading="loading")
  template(slot="skeleton-template")
    skeleton-template
  template(slot="origin-template")
    .wuwow-card
      .wuwow-card-body
        h4.h4-contract-title(v-if="accountRole === 'parent'") {{ $t('student') }}{{ $t('contract.plan') }}
        h4.h4-contract-title(v-else) {{ $t('my') }}{{ $t('exclusive') }}{{ $t('contract.plan') }}
        //-  有合約
        template(v-if="contract !== null && contract")
          //- 無限堂
          permanently-contract-info(
            v-if="contract.product_id === permanentlyProductId"
            :contract_info="contract"
            :vipAccountBaseProfile="vipAccountBaseProfile"
          )
            //- 一般合約
          contract-info(
            v-else
            :contract_info="contract"
            :vipAccountBaseProfile="vipAccountBaseProfile"
          )
          b-pagination.mt-5(
            v-model="currentPage"
            align='center'
            :total-rows='contractPagination.total'
            :per-page='Number(contractPagination.per_page)'
            @input="getContractOwnHistory()"
          )
        //- 無合約
        template(v-else)
          no-contract(:newRule="newRule")

</template>

<script type="text/javascript">
// 合約規則
// 學員申請 分享轉讓 / 暫停 需先讓禮書人員在太極內部操作並於 Contract history View 同意
// 只有 分享轉讓 / 暫停 被分享轉讓/暫停學員需要於學員後台點選同意
// 被分享者無合約
// 被分享者若有合約則直接課程點數直接加入原本合約(合約期限看禮書太極內部調整)
import { mapState, mapActions } from 'vuex';
import skeleton from '@/components/skeleton/index.vue';
import skeletonTemplate from '@/views/dojo/account/contracts/skeleton/index.vue';
import contractInfo from '@/components/vip/contracts/contractInfo';
import noContract from '@/components/vip/contracts/noContract';
import permanentlyContractInfo from '@/components/vip/contracts/permanentlyContractInfo';
import lioshutanApi from '@/api';
import contractConstant from '@/constants/contract';

export default {
  name: 'VipContracts',

  components: {
    contractInfo,
    noContract,
    permanentlyContractInfo,
    skeleton,
    skeletonTemplate,
  },

  data() {
    return {
      permanentlyProductId: contractConstant.PERMANENTLY_PRODUCT_ID,
      vipAccountBaseProfile: {},
      currentPage: 1,
      perPage: 1,
      contract: null,
      contractPagination: {},
      accountRole: localStorage.getItem('account_role'),
      newRule: false,
      loading: false,
    };
  },

  computed: {
    ...mapState({
      contractOwnHistory: (state) => state.vipContract.contractOwnHistory,
    }),
  },

  async created() {
    this.startLoading();
    await this.getContractOwnHistory();
    this.endLoading();
  },

  provide() {
    return {
      // 用於重啟 BootstrapVue modal
      loading: this.loading,
    };
  },

  methods: {
    ...mapActions('vipContract', ['fetchContractOwnHistory']),
    async getContractOwnHistory() {
      const query = {
        perPage: this.perPage,
        page: this.currentPage,
      };
      this.loading = true;
      try {
        await this.fetchContractOwnHistory(query);
        const baseProfileRes = await lioshutanApi.vip.getBaseProfile();
        this.newRule = baseProfileRes.data.customized_classroom_rule.new_rule;
        this.vipAccountBaseProfile = baseProfileRes.data.profile_base;
        this.contract = this.contractOwnHistory.data.contract[0];
        this.contractPagination = this.contractOwnHistory.meta.pagination;
      } catch (error) {
        this.contract = null;
      } finally {
        this.loading = false;
      }
    },
    startLoading() {
      this.loading = true;
    },

    endLoading() {
      this.loading = false;
    },

    toContractPage() {
      const resolve = this.$router.resolve({
        name: 'program-contract',
        query: {
          page: this.currentPage,
          perPage: this.perPage,
        },
      });
      window.open(resolve.href, '_blank');
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/vips/contractList';
</style>
