<template lang="pug">
div
  // 第一筆總金額為4990
  div(v-if='contract_info.payment && contract_info.payment[0].money == (system_set_fee + monthly_payment)')
    div
      fee-paid-status(:payment="contract_info.payment[0].payment_status" :type="'system_set_fee'" :dollar="system_set_fee")
      br
      fee-paid-status(:payment="contract_info.payment[0].payment_status" :type="'monthly_payment'" :dollar="monthly_payment")
    div(v-if='contract_info.payment.length > 1')
      template(v-for='(payment, index) in contract_info.payment')
        div(v-if='payment.money == monthly_payment && !paidAlready' :key='index')
          | {{ onGetPaymentPaid(payment) }}
      | {{ $t('spgateway_credit_card_binding_eduction') }} {{ $t('monthly_payment') }} ${{monthly_payment}}{{ $t('dollar') }}
      // 已付款
      span.badge.badge-success.badge-pill(v-if='paidAlready') {{ $t('paid') }}
      // 未付款
      span(v-else)
        span.badge.badge-danger.badge-pill {{ $t('unpaid') }}
        unpaid-setting
  // 剩下每月需繳
  div(v-else)
    div
      fee-paid-status(:payment_status="contract_info.payment[0].payment_status" :type="'system_set_fee'" :dollar="system_set_fee")
    div(v-if='contract_info.payment.length == 1')
      unpaid-setting
    div(v-else-if='contract_info.payment.length > 1')
      template(v-for='(payment, index) in contract_info.payment')
        div(v-if='payment.money == monthly_payment && !paidAlready' :key='index')
          | {{ onGetPaymentPaid(payment) }}
      | {{ $t('spgateway_credit_card_binding_eduction') }} {{ $t('monthly_payment') }} ${{monthly_payment}}{{ $t('dollar') }}
      span.badge.badge-success.badge-pill(v-if='paidAlready') {{ $t('paid') }}
      span(v-else)
        span.badge.badge-danger.badge-pill {{ $t('unpaid') }}
        unpaid-setting

</template>
<script type="text/javascript">
import i18n from './../lang.js';
import FeePaidStatus from './feePaidStatus';
import UnpaidSetting from './unpaidSetting';
import contractConstant from '@/constants/contract';

export default {
  name: 'PermanentlyPayWay',

  components: {
    FeePaidStatus,
    UnpaidSetting,
  },

  props: {
    // eslint-disable-next-line
    contract_info: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },

  i18n,

  data() {
    return {
      system_set_fee: contractConstant.PERMANENTLY_SYSTEM_SET_FEE,
      monthly_payment: contractConstant.PERMANENTLY_MONTHLY_PAYMENT,
      paidAlready: false,
    };
  },

  computed: {

  },

  watch: {},

  created() {},

  methods: {
    // 技術債
    onGetPaymentPaid(payment){
      if (payment.payment_status === 'paid'){
        this.paidAlready = true;
      }
    },
  },
};
</script>
