<template lang="pug">
  b-modal#vip_share_modal(size='lg' effect='fade' :ok-title="$t('confirm')" ok-only :title="$t('contract.share_detailed_info_list_new')")
    div(v-if="contractInfo && contractInfo.vip_history")
      div(v-for="detail in contractInfo.vip_history" :key="detail.id")
        tr.row.col-lg-12
          th.col-lg-1 {{ $t('type') }}
          td.col-lg-2 {{ transType(detail.action_type) }}
          th.col-lg-1 {{ $t('related_person') }}
          td.col-lg-2 {{ detail.transfer_vip_english_name }}
          th.col-lg-1 {{ $t('class.class_count') }}
          td.col-lg-2 {{ detail.amount }} {{ $t('class.class') }}
          th.col-lg-1 {{ $t('end_day') }}
          td.col-lg-2
            | {{ detail.month_amount }} {{ $t('month') }}{{ $t('contract.and') }} {{ detail.day_amount }} {{ $t('contract.day') }}
        hr
    h3.mt-2 {{ $t('precautions') }}:
    br
    span(v-html="$t('transfer_share_notice')")
</template>

<script type="text/javascript">
import i18n from '../lang';
export default {
  name: 'VipContractShareModal',

  components: {},

  props: {
    // eslint-disable-next-line
    contractInfo: {
      type: Object,
      default: () => {
        return {
          product_name: '查無方案',
        };
      },
    },
  },

  i18n,

  data() {
    return {};
  },

  computed: {},

  watch: {},

  created() {
  },

  methods: {
    transType(type) {
      if (type === 'transfer') {
        return this.$t('sharer');
      } else if (type === 'receive') {
        return this.$t('receiver');
      } else {
        return type;
      }
    },
  },
};
</script>
