export default {
  messages: {

    en: {
      not_agree_reading_learn_rule: '',
      you_have: 'You have',
      read_learn_term_notice: '',
    },

    tw: {
      not_agree_reading_learn_rule: '您尚未同意學習條款，為確保您的學習權益，請盡速閱讀後按下同意按鈕',
      you_have: '您已於',
      read_learn_term_notice: '審慎閱讀完學習條款並同意學習條款',
    },

  },
};
