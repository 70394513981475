<template lang="pug">
div
  | {{ payment_list.payment_name }} ${{ payment_list.money }}
  span.badge.badge-success.badge-pill(v-if="payment_list.payment_status == 'paid'") {{ $t('paid') }}
  span.badge.badge-danger.badge-pill(v-else-if="payment_list.payment_status == 'unpaid'") {{ $t('unpaid') }}
  span.badge.badge-primary.badge-pill(v-else-if="payment_list.payment_status == 'refund'") {{ $t('refund') }}
  br
</template>
<script type="text/javascript">
export default {
  name: 'PaymentList',

  components: {},

  props: {
    // eslint-disable-next-line
    payment_list: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },

  data() {
    return {};
  },

  computed: {},

  watch: {},

  created() {},

  methods: {},
};
</script>
