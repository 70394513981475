<template lang="pug">
  b-modal#vip_transfer_and_share_apply_modal(size='lg' effect='fade' :ok-title="$t('agree')" :cancel-title="$t('disagree')" :title="$t('contract.share_detailed_info_apply_list')" @ok="submitConfirm(3)" @cancel="submitConfirm(4)")
    div(v-if="contractInfo && contractInfo.vip_products_adjustment")
      tr.row.col-lg-12.mb-1
        th.col-lg-3 {{ $t('undertaker') }}
        td.col-lg-3 {{ contractInfo.vip_products_adjustment.receive_vip_english_name }}
      tr.row.col-lg-12.mb-1
        th.col-lg-3
          | {{ $t('transfer_out') }}{{ $t('class.class_count') }}
        td.col-lg-3 {{ contractInfo.vip_products_adjustment.amount }} {{ $t('class.class') }}
        th.col-lg-3 {{ $t('transfer_out') }}{{ $t('end_day') }}
        td.col-lg-3
          | {{ contractInfo.vip_products_adjustment.month_amount }} {{ $t('month') }} {{ $t('contract.and') }} {{ contractInfo.vip_products_adjustment.day_amount }} {{ $t('contract.day') }}
      tr.row.col-lg-12
        th.col-lg-3 {{ $t('undertaker') }}{{ $t('start_date') }}
        td.col-lg-3 {{ contractInfo.vip_products_adjustment.receive_contract_start }}
        th.col-lg-3 {{ $t('undertaker') }}{{ $t('end_date') }}
        td.col-lg-3 {{ contractInfo.vip_products_adjustment.receive_contract_end }}
      hr
      tr.row.col-lg-12
        th.col-lg-12.mb-3
          h3 {{ $t('precautions') }}:
        th.col-lg-12.mb-4
          span(v-html="$t('transfer_share_notice')")
</template>

<script type="text/javascript">
import { alertMessage } from '@/utils/sweetAlert.js';
import i18n from '../lang';
import LioshutanApi from '@/api';
export default {
  name: 'VipContractTransferAndShareModal',

  components: {},

  props: {
    // eslint-disable-next-line
    contractInfo: {
      type: Object,
      default: () => {
        return {
          product_name: '查無方案',
        };
      },
    },
  },

  i18n,

  data() {
    return {};
  },

  computed: {},

  watch: {},

  created() {},

  methods: {
    async submitConfirm(status){
      try {
        const params = {
          vpai: this.contractInfo.vip_products_adjustment.id,
          status: status,
        };
        const confirmRes = await LioshutanApi.vip.confirmTransferProduct(params);
        if (confirmRes.message === 'OK') {
          alertMessage(this.$t('success'), 'success', '').then(() => {
            location.reload();
          });
        }
      } catch (err) {
        alertMessage(this.$t('fsiled'), 'error', '');
      }
    },
  },
};
</script>
