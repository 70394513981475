<template lang="pug">
div
  button.btn.btn-primary.mb-2(v-if="role=== student" @click="readLearnRule(contract_info)")
    | {{ $t('reading') }}{{ $t('learn') }}{{ $t('terms') }}
  b-alert(variant='info' show)
    span(v-if="contract_info.learn_rule == '0000-00-00 00:00:00' || !contract_info.learn_rule") {{ $t('not_agree_reading_learn_rule') }}
    span(v-else) {{ $t('you_have') }} {{ contract_info.learn_rule }} {{ $t('read_learn_term_notice') }}
</template>
<script type="text/javascript">
import i18n from './lang.js';
import { mapState } from 'vuex';
import commonConstants from '@/constants/common';
export default {
  name: 'ReadLearnRule',

  components: {},

  props: {
    // eslint-disable-next-line
    contract_info: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },

  i18n,

  data() {
    return {
      student: commonConstants.ROLE.STUDENT,
    };
  },

  computed: {
    ...mapState({
      role: state => state.auth.role,
      contractOwnHistory: (state) => state.vipContract.contractOwnHistory,
    }),
  },

  watch: {},

  created() {},

  methods: {
    readLearnRule(item) {
      this.$router.push({ name: 'learn-rule-view', params: { data: item }});
    },

    toContractPage() {
      const resolve = this.$router.resolve({
        name: 'program-contract',
        query: {
          page: this.contractOwnHistory.meta.pagination.current_page,
          perPage: this.contractOwnHistory.meta.pagination.per_page,
        },
      });
      window.open(resolve.href, '_blank');
    },
  },
};
</script>
