export default {
  messages: {

    en: {
      unlimited_lessons: 'Unlimited Lessons',
      system_set_fee: 'System Set Fee',
      per_fee: '(Per)',
      dollar: 'Dollar',
      monthly_payment: 'Monthly Payment',
      spgateway_credit_card_binding_eduction: 'Spgateway Credit Card Binding Eduction',
      click_me_to_pay: 'Click Me To Pay',
      click_me_to_bind_credit: 'Click Me To Bind Credit Card',
      get_start: 'Start',
    },

    tw: {
      unlimited_lessons: '無限堂',
      system_set_fee: '系統設定費',
      per_fee: '(乙筆)',
      dollar: '元',
      monthly_payment: '每月月付款',
      spgateway_credit_card_binding_eduction: '智付通信用卡綁定扣款',
      click_me_to_pay: '點我付款',
      click_me_to_bind_credit: '點我綁定付款信用卡',
      get_start: '啟始',
    },

  },
};
