<template lang="pug">
div
  b-modal#supplemental_agreement_history_modal(
    size='lg'
    effect='fade'
    title="查看協議歷程"
    hide-footer
  )
    div(class="table-container")
      table(class="table table-borderless text-center  overflow-y")
        thead
          tr
            th.col-lg-3.custom-table-list 建立日期
            th.col-lg-3.custom-table-list 目前狀態
            th.col-lg-3.custom-table-list 異動人
            th.col-lg-3.custom-table-list 內容
        tbody
          tr(v-for="record in vipAdditionTermRecord.vipContractAdditionTermRecords" :key="record.id")
            td.col-lg-3.custom-table-list {{ formatDateTime(record.createdAt.date) }}
            td.col-lg-3.custom-table-list {{ record.status === 'rejected' ? '拒絕' : record.status === 'approved' ? '同意' : record.status === 'waiting' ? '稍後決定' : '' }}
            td.col-lg-3.custom-table-list {{ record.userType === 'user' ? '使用者' : (record.userType === 'admin' ? '客服人員' : '系統') }}
            td(class="col-lg-3 custom-table-list")
              button(class="btn btn-primary" @click="openDetailsModal(record.vipContractAdditionTermId, record.status)") 查看內容
    div(class="cancleBtn")
      button.btn.btn-primary(@click="closeBtn") OK

  b-modal#content_modal(
    size='lg'
    effect='fade'
    hide-footer
    :title="'內容詳情'"
  )
    template(v-if="currentStatus === 'waiting'")
      div(class="title")
        h3 {{ vipContractAdditionTerm.title }}
      div(class="content ckeditor-table-style")
        p(v-html="vipContractAdditionTerm.waitingDirections")
      template(v-if="identity !== 'parent'")
        div(v-if="isOnWaiting" class="reply-choices")
          div(class="mx-1")
            input(type="radio" value="approved" v-model="choice" id="agreeBtn" class="mr-1")
            label(class="form-check-label" for="agreeBtn")  同意
          div(class="mx-1")
            input(type="radio" value="rejected" v-model="choice" id="rejectBtn" class="mr-1")
            label(class="form-check-label" for="rejectBtn") 拒絕
        div(class="mx-1 mt-3 ckeditor-table-style")
          p(v-show="choice === 'approved'" class="" v-html="vipContractAdditionTerm.approvedDirections")
          div(v-if="choice === 'approved'" class="mx-1 mt-3 ckeditor-table-style")
            h2(class="agree-gift-content") 同意後贈送好禮
            p(v-html="vipContractAdditionTerm.note")
          p(v-show="choice === 'rejected'" class="" v-html="vipContractAdditionTerm.rejectedDirections")
        div(v-if="isOnWaiting" class="submit-btn")
          button(@click="handleSendResults" :disabled="!choice") 送出
    template(v-if="currentStatus === 'approved'")
      div(class="content ckeditor-table-style")
        p(v-html="vipContractAdditionTerm.waitingDirections")
    template(v-if="currentStatus === 'rejected'")
      div(class="content ckeditor-table-style")
        p(v-html="vipContractAdditionTerm.waitingDirections")
</template>

<script type="text/javascript">
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
// import { alertMessage } from '@/utils/sweetAlert.js';
import i18n from '../lang';
// import LioshutanApi from '@/api';
export default {
  name: 'SupplementalAgreementHistoryModal',

  components: {},

  i18n,

  data() {
    return {
      currentStatus: '',
      choice: null,
      isOnWaiting: false,
      identity: localStorage.getItem('account_role'),
    };
  },

  computed: {
    ...mapState('supplementaryAgreement', [
      'vipAdditionTermRecord',
      'vipContractAdditionTerm',
      'confirmStatusInfo',
    ]),
  },

  watch: {},
  methods: {
    ...mapActions('supplementaryAgreement', [
      'getVipAdditionTermRecord',
      'getCheckAdditionTerm',
      'sendResults',
      'getVipContractAdditionTerm',
    ]),

    async getRecordInfo(){
      await this.getVipAdditionTermRecord();
    },

    closeBtn(){
      this.$bvModal.hide('supplemental_agreement_history_modal');
    },

    async openDetailsModal(vipContractAdditionTermId, status) {
      this.choice = null; // 初始化資料
      this.currentStatus = status;
      console.log(this.confirmStatusInfo.checkStatus);
      if (this.confirmStatusInfo.checkStatus === true) {
        this.isOnWaiting = true;
      }
      await this.getVipContractAdditionTerm(vipContractAdditionTermId);
      this.$bvModal.show('content_modal'); // 使用 BootstrapVue 的 $bvModal 來打開模態框
    },

    // 送出結果 同意approved , 拒絕rejected
    async handleSendResults() {
      await this.sendResults({ choice: this.choice });
      this.getRecordInfo();
      this.$bvModal.hide('content_modal');
    },

    // 整理時間格式
    formatDateTime(dateTimeString) {
      return moment(dateTimeString).format('YYYY-MM-DD HH:mm:ss');
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  border: none;
}

.table-container {
  max-height: 500px;  // 你可以根据需要调整这个值
  overflow-y: auto;
}
.cancleBtn {
  display: flex;
  justify-content: end;
  margin-top: 1rem;
}

.content {
  overflow: hidden;
  padding: 8px;
  border: 1px solid #555755;
  border-radius: 10px;
  min-height:500px;
}

.reply-choices {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
}

.submit-btn {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  button {
    padding: 0.25rem 0.75rem;
    background-color: #fff;
    border: 1px solid #82c1ea;
    border-radius: 10px;
    &:hover {
      color: #FFF;
      background-color: #82c1ea;
    }
  }
  button:disabled {
    background-color: dimgrey;
    cursor: no-drop;
    color: linen;
    opacity: 0.5;
  }
}
.custom-table-list {
  width: 25%;
}

.btn {
  padding: 0.375rem 0.5rem;
}

.agree-gift-content {
  font-size: 1rem;
  font-weight: bold;
}
</style>
