<template lang="pug">
div
  form(:action="appBaseApi + toPayUrl" method='post')
    input(type='submit' :value="$t('click_me_to_pay')")
    input(type='hidden' name='uid' v-model='vipAccountBaseProfile.user_id')
    input(type='hidden' name='uac' v-model='vipAccountBaseProfile.user_account')

</template>
<script type="text/javascript">
import urlConstant from '@/constants/url';
import { mapState } from 'vuex';

export default {
  name: 'ToPaid',

  components: {},

  props: {},

  data() {
    return {
      appBaseApi: process.env.VUE_APP_BASE_API,
      toPayUrl: urlConstant.VIP.TO_PAY_URL,
    };
  },

  computed: {
    ...mapState({
      vipAccountBaseProfile: (state) => state.vipAccount.vipAccountBaseProfile,
    }),
  },

  methods: {
  },
};
</script>
