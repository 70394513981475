<template lang="pug">
  b-modal#vip_suspend_apply_modal(size='lg' effect='fade' :ok-title="$t('agree')" :cancel-title="$t('disagree')" :title="$t('contract.share_detailed_info_list')" @ok="submitConfirm(3)" @cancel="submitConfirm(4)")
    div(v-if="contractInfo && contractInfo.vip_products_adjustment")
      tr.row.col-lg-12.mb-1
        th.col-lg-3 {{ $t('apply') }}{{ $t('day') }}
        td.col-lg-3 {{ contractInfo.vip_products_adjustment.created_at }}
      tr.row.col-lg-12.mb-1
        th.col-lg-3
          | {{ $t('contract.suspend_deadline') }}({{ $t('month') }})
        td.col-lg-3 {{ contractInfo.vip_products_adjustment.month_amount }} {{ $t('month') }}
        th.col-lg-3 {{ $t('contract.suspend_deadline') }}({{ $t('day') }})
        td.col-lg-3 {{ contractInfo.vip_products_adjustment.day_amount }} {{ $t('contract.day') }}
      tr.row.col-lg-12.mb-1
        th.col-lg-3
          | {{ $t('contract.suspend_deadline') }}{{ $t('start_date') }}
        td.col-lg-3 {{ contractInfo.vip_products_adjustment.suspension_start }}
        th.col-lg-3
          | {{ $t('contract.suspend_deadline') }}{{ $t('end_date') }}
        td.col-lg-3 {{ contractInfo.vip_products_adjustment.suspension_end }}
      tr.row.col-lg-12
        th.col-lg-3 {{ $t('contract.latest_contract_expiry_date') }}
        td.col-lg-3 {{ contractInfo.vip_products_adjustment.contract_end }}
      hr
      tr.row.col-lg-12
        th.col-lg-12.mb-3
          h3 {{ $t('precautions') }}:
        th.col-lg-12.mb-4 {{ $t('suspend_record_notice') }}
        th.col-lg-12 {{ $t('contact_wuwow') }}

</template>

<script type="text/javascript">
import { alertMessage } from '@/utils/sweetAlert.js';
import i18n from '../lang';
import LioshutanApi from '@/api';
export default {
  name: 'VipContractSuspendApplyModal',

  components: {},

  props: {
    // eslint-disable-next-line
    contractInfo: {
      type: Object,
      default: () => {
        return {
          product_name: '查無方案',
        };
      },
    },
  },

  i18n,

  methods: {
    async submitConfirm(status){
      try {
        const params = {
          vpai: this.contractInfo.vip_products_adjustment.id,
          status: status,
        };
        const confirmRes = await LioshutanApi.vip.confirmSuspensionProduct(params);
        if (confirmRes.message === 'OK') {
          alertMessage(this.$t('success'), 'success', '').then(() => {
            location.reload();
          });
        }
      } catch (err) {
        alertMessage(this.$t('failed'), 'error', '');
      }
    },
  },
};
</script>
