import companyConstant from '@/constants/company';
export default {
  messages: {

    en: {
      suspend_record_notice: '',
      contact_wuwow: '',
      transfer_share_notice: '',
      transfer_notice_introduce: '',
      share_notice_introduce: '',
    },

    tw: {
      suspend_record_notice: '課程學習期間，會員享有課程暫停權一次。最長可申請6個月，不得分次暫停，暫停期間不得使用課程。申請暫停後合約到期日將依照暫停期限往後遞延。',
      contact_wuwow: '如有任何問題，請撥打客服專線' + companyConstant.INFO.CONTACT_PHONE + '，WUWOW Jr將竭誠為您服務，謝謝。',
      transfer_share_notice: '<ul class="transfer-notice-content"><ol>1. 承接者不享有轉讓權及分享權，且學員轉出之堂數不得再次變動或取回。</ol><ol>2. 主約人與承接人爾後延期則需各自申請辦理。</ol><ol>3. 如為【跨平台分享/轉讓】，本學員(法定代理人或監護人)清楚知悉【WUWOW】與【WUWOW Jr】兩平台之服務差異，堂數將依照下列方式轉換：<ul><li>如為【WUWOW to WUWOW Jr】，本學員同意將原購買之【WUWOW】剩餘堂數除以三，換算為【WUWOW Jr】可使用課程堂數。</li><li>如為【WUWOW Jr to WUWOW】，本學員同意將原購買之【WUWOW Jr】剩餘堂數乘以三，換算為【WUWOW】可使用課程堂數。</li></ul></ol></ul>',
      transfer_notice_introduce: '可將<span class="text-danger">合約剩餘所有堂數</span>以及 <span class="text-danger">所有使用期限</span> 一併轉讓給 1 名親友。',
      share_notice_introduce: '<span class="text-danger">合約開始日一個月內</span>，可將 <span class="text-danger">合約堂數與課程期間的一併對半</span> (平均)分享給1名親友。',
    },
  },
};
