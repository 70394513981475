var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._v(_vm._s(_vm.$t('spgateway_credit_card_binding_eduction')) + " " + _vm._s(_vm.$t(_vm.type)) + " $" + _vm._s(_vm.dollar) + _vm._s(_vm.$t('dollar'))), _vm.payment_status == 'paid' ? _c('span', {
    staticClass: "badge badge-success badge-pill"
  }, [_vm._v(_vm._s(_vm.$t('paid')))]) : _vm.payment_status == 'unpaid' ? _c('span', {
    staticClass: "badge badge-danger badge-pill"
  }, [_vm._v(_vm._s(_vm.$t('unpaid')))]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }