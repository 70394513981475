<template lang="pug">
div
  table.table.table-responsive-sm.table-contract
    tbody
      tr
        th {{ $t('vip.vip') }}{{ $t('name') }}
        td {{ vipAccountBaseProfile.chinese_name }} {{ vipAccountBaseProfile.english_name }}
      tr
        th {{ $t('contract.name') }}
        td {{ contract_info.product_name }}
      template(v-if="isTransferContract()")
        tr(v-if="contract_info.total_session !== null")
          th {{ $t('transfer_session') }}
          td {{ contract_info.total_session }} {{ $t('session') }}
        tr
          th {{ $t('transfer_term') }}
          td {{ $t('original_contract_period') }}
      template(v-else-if="!isTransferContract()")
        tr
          th {{ $t('contract.purchased_lessons') }}
          td {{ contract_info.num_session }}
        tr(v-if="contract_info.gift_session !== null")
          th {{ $t('contract.send_lessons') }}
          td {{ contract_info.gift_session }}
        tr(v-if="contract_info.total_session !== null")
          th {{ $t('class.lesson') }}{{ $t('total') }}{{ $t('class.class_count') }}
          td {{ contract_info.total_session }}
        tr(v-if="contract_info.orig_term !== null")
          th {{ $t('contract.purchased_end_day') }}({{ $t('month') }})
          td {{ contract_info.orig_term }}
        tr(v-if="contract_info.gift_term !== null")
          th {{ $t('contract.send_end_day') }}({{ $t('month') }})
          td {{ contract_info.gift_term }}
        tr(v-if="contract_info.total_term !== null")
          th {{ $t('class.lesson') }}{{ $t('total') }}{{ $t('period') }}({{ $t('month') }})
          td {{ contract_info.total_term }}
      tr(v-if="contract_info.learning_time !== null")
        th {{ $t('learn') }}{{ $t('period') }}
        td
          template(v-for='(learning_time, index) in contract_info.learning_time')
            template(v-if='index == 0')
              learning-time(:learning_time="learning_time")
      tr(v-if="contract_info.orig_price !== null")
        th {{ $t('contract.plan') }}{{ $t('price') }}
        td {{ contract_info.orig_price }}
      tr(v-if="contract_info.refund_payment.length !== 0")
        th 退款
        td
          .d-flex.flex-wrap
            span.badge.badge-danger.badge-pill.mr-2( :class="{'mb-2': contract_info.refund_payment.length > 2}" v-for="(refund, index) in contract_info.refund_payment") {{ changeTimeFormat(refund.paid_date) }} / ${{ refund.money }}
      //- tr(v-if="contract_info.one_pay_disc_price !== null")
      //-   th {{ $t('contract.lump_sum_discount') }}
      //-   td {{ contract_info.one_pay_disc_price }}
      //- tr(v-if="contract_info.disc_price !== null")
      //-   th {{ $t('discount') }}{{ $t('price') }}
      //-   td {{ onCountDisc(contract_info) }}

      tr()
        th {{ $t('total') }}{{ $t('discount') }}{{ $t('price') }}
        td {{ onCountDisc(contract_info) }}

      tr(v-if="contract_info.selling_price !== null")
        th {{ $t('total') }}{{ $t('price') }}
        td {{ contract_info.selling_price }}
      tr(v-if="contract_info.payment !== null")
        th {{ $t('pay') }}{{ $t('way') }}
        td
          template(v-for='(payment, index) in contract_info.payment')
            payment-list(:payment_list="payment")
      tr(v-if="contract_info.paid_date !== null")
        th {{ $t('pay') }}{{ $t('date') }}
        td {{ contract_info.paid_date }}
      tr(v-if="contract_info.purchased !== null")
        th {{ $t('purchas') }}{{ $t('date') }}
        td {{ contract_info.purchased }}
      tr(v-if="contract_info.vip_history_transfer_amount")
        th
          | {{ $t('shared') }}{{ $t('total') }}{{ $t('class.class_count') }}
          br
          br
          | {{ $t('shared') }}{{ $t('total') }}{{ $t('end_day') }}
        td
          | {{ contract_info.vip_history_transfer_amount }} {{ $t('class.class') }}
          br
          br
          | {{ contract_info.vip_history_transfer_month_amount }} {{ $t('month') }} {{ $t('contract.and') }} {{ contract_info.vip_history_transfer_day_amount }} {{ $t('contract.day') }}
          span.badge.badge-primary.badge-pill(v-b-modal.vip_share_modal style='cursor: pointer').
            {{ $t('view_details') }}
      //- 增補協議綁訂方案+合約為被承接人
      tr(v-if="checkHasOwnerContract && findSameVipProductId(contract_info) && vipAdditionTermRecord.vipContractAdditionTermRecords && vipAdditionTermRecord.vipContractAdditionTermRecords.length > 0")
        th 增補協議歷程
        td
          span(class="btn btn-primary mb-2")(v-b-modal.supplemental_agreement_history_modal style='cursor: pointer').
            查看歷程
      //- 增補協議綁訂身份+合約為被承接人，增補歷程顯示在最後一筆合約的資料內
      tr(v-if="!checkHasOwnerContract && isContractLastOne  && vipAdditionTermRecord.vipContractAdditionTermRecords && vipAdditionTermRecord.vipContractAdditionTermRecords.length > 0")
        th 增補協議歷程
        td
          span(class="btn btn-primary mb-2")(v-b-modal.supplemental_agreement_history_modal style='cursor: pointer').
            查看歷程
      tr(v-if="contract_info.vip_suspensions")
        th {{ $t('suspend') }}{{ $t('record') }}
        td
          span.badge.badge-primary.badge-pill(v-b-modal.vip_suspend_record_modal style='cursor: pointer').
            {{ $t('view_details') }}
      tr(v-if="contract_info.vip_products_adjustment && contract_info.vip_products_adjustment.action_type=='transfer'")
        th {{ $t('transfer') }} / {{ $t('share') }}
        td
          span.badge.badge-primary.badge-pill(v-b-modal.vip_transfer_and_share_apply_modal style='cursor: pointer').
            {{ $t('view_details') }}
      tr(v-if="contract_info.vip_products_adjustment && contract_info.vip_products_adjustment.action_type == 'suspension'")
        th {{ $t('suspend') }}{{ $t('apply') }}
        td
          span.badge.badge-primary.badge-pill(v-b-modal.vip_suspend_apply_modal style='cursor: pointer').
            {{ $t('view_details') }}
      tr(v-if="contract_info.product_id && contract_info.product_id !== 0")
        th {{ $t('learn') }}{{ $t('terms') }}
        td
          read-learn-rule(:contract_info="contract_info")

  //- 「我的專屬方案」切換頁數時重啟ˋ
  supplemental-agreement-history
  vip-contract-share(v-if="!loading", :contractInfo="contract_info")
  vip-contract-suspend-apply(v-if="!loading",:contractInfo="contract_info")
  vip-contract-suspend-record(v-if="!loading",:contractInfo="contract_info")
  vip-contract-transfer-and-share-apply(v-if="!loading",:contractInfo="contract_info")

</template>

<script type="text/javascript">
import LearningTime from './../learningTime';
import PaymentList from './paymentList';
import readLearnRule from './../readLearnRule';
import VipContractShare from '@/components/modal/contracts/vipShare';
import VipContractSuspendApply from '@/components/modal/contracts/vipSuspendApply';
import VipContractSuspendRecord from '@/components/modal/contracts/vipSuspendRecord';
import VipContractTransferAndShareApply from '@/components/modal/contracts/vipTransferAndShareApply';
import SupplementalAgreementHistory from '@/components/modal/contracts/supplementalAgreementHistory';
import contractConstant from '@/constants/contract';
import i18n from '@/components/vip/contracts/contractInfo/lang.js';
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import lioshutanApi from '@/api';

export default {
  name: 'VipContractInfo',

  components: {
    PaymentList,
    LearningTime,
    readLearnRule,
    VipContractShare,
    VipContractSuspendApply,
    VipContractSuspendRecord,
    VipContractTransferAndShareApply,
    SupplementalAgreementHistory,
  },

  i18n,

  props: {
    // eslint-disable-next-line
    contract_info: {
      type: Object,
      default: () => {
        return {
          product_name: '查無方案',
        };
      },
    },
    // eslint-disable-next-line
    vipAccountBaseProfile: {
      type: Object,
      default: () => {
        return {
          chinese_name: '中文姓名',
          english_name: 'English Name',
        };
      },
    },
  },

  data() {
    return {
      transferPlatformOrderType: contractConstant.CONTRACT_TYPE.TRANSFER_PLATFORM,
      vipProductId: null,
      contractType: '',
    };
  },

  computed: {
    ...mapState('supplementaryAgreement', [
      'confirmStatusInfo',
      'vipAdditionTermRecord',
    ]),
    ...mapState({
      contractOwnHistory: (state) => state.vipContract.contractOwnHistory,
    }),
    /**
     * 1. 若回傳的"contract_type": "receive", 則為被轉讓者，需要在合約下方顯示協議歷程
     * 2. 若回傳owner，則照原本流程走( findSameVipProductId )，判斷方案id是否符合增補協議綁定的方案id
     */
    checkHasOwnerContract() {
      if (this.contractType === 'receive'){
        return false;
      }
      return true;
    },
    /*
     * 判斷是否為合約的最後一筆
    */
    isContractLastOne(){
      const totalPage = this.contractOwnHistory.meta.pagination.total;
      const currentPage = this.contractOwnHistory.meta.pagination.current_page;
      if (totalPage === currentPage){
        return true;
      }
      return false;
    },
  },
  created() {
    this.getCheckStatus();
    this.getRecordInfo();
    this.hasContract();
  },

  inject: ['loading'],

  methods: {
    ...mapActions('supplementaryAgreement', [
      'getCheckAdditionTerm',
      'getVipAdditionTermRecord',
    ]),

    onCountDisc(data) {
      let totalDiscount = null;
      // 基本折扣
      const disc_price = Number(data.disc_price);
      // 一次付清折扣
      const one_pay_disc_price = Number(data.one_pay_disc_price);
      // 其他折扣
      const other_disc = Number(data.other_disc);
      // 續約折扣
      const renewal_disc = Number(data.renewal_disc);
      // 業務權限折扣
      const bd_quota = Number(data.bd_quota);
      totalDiscount = Number(disc_price + bd_quota + one_pay_disc_price + other_disc + renewal_disc);
      return totalDiscount;
    },

    async getRecordInfo(){
      await this.getVipAdditionTermRecord();
      console.log(this.vipAdditionTermRecord.vipContractAdditionTermRecords.length);
    },
    async getCheckStatus(){
      await this.getCheckAdditionTerm();
      // 將confirmStatusInfo.vipProductId的值存起來，判斷合約是否顯示歷程
      this.vipProductId = this.confirmStatusInfo.vipProductId;
    },
    // 找到對應的方案顯示協議歷程
    findSameVipProductId(item) {
      return item.learning_time && item.learning_time.some((productItem) => productItem.vip_product_id === this.vipProductId);
    },
    async hasContract() {
      /**
       * 1. 若回傳的"contract_type": "receive", 則為被轉讓者，需要在合約下方顯示協議歷程
       * 2. 若回傳owner，則照原本流程走( findSameVipProductId )，判斷方案id是否符合增補協議綁定的方案id
       */
      const result = await lioshutanApi.vip.getContractIdentity();
      this.contractType = result.data.data.contract_type;
    },
    isTransferContract(){
      return this.contract_info.order_type === contractConstant.CONTRACT_TYPE.TRANSFER_PLATFORM;
    },
    changeTimeFormat(paid_date) {
      return moment(paid_date).format('YYYY-MM-DD');
    },
  },
};
</script>

<style lang="scss" scoped>
.agreement-class {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
