<template lang="pug">
  div
    table.table.table-responsive-sm.table-contract
      tbody
        tr
          th {{ $t('vip.vip') }}{{ $t('name') }}
          td {{ vipAccountBaseProfile.chinese_name }} {{ vipAccountBaseProfile.english_name }}
        tr
          th {{ $t('contract.name') }}
          td {{ contract_info.product_name }}
        tr
          // 固定
          th {{ $t('contract.purchased_lessons') }}
          td {{ $t('unlimited_lessons') }}
        tr
          // 固定
          th {{ $t('class.lesson') }}{{ $t('total') }}{{ $t('class.class_count') }}
          td {{ $t('unlimited_lessons') }}
        tr
          th {{ $t('learn') }}{{ $t('period') }}
          td
            template(v-for='(learning_time, index) in contract_info.learning_time')
              template(v-if='index == 0')
                | {{ cutStartTime(learning_time.learning_time) }}
                span.badge.badge-success.badge-pill(v-if="learning_time.status == 1") {{ $t('enable') }}
                span.badge.badge-danger.badge-pill(v-else) {{ $t('disable') }}
        tr
          // 固定
          th {{ $t('contract.plan') }}{{ $t('price') }}
          td {{ $t('system_set_fee') }}{{ $t('per_fee') }} $3000 {{ $t('dollar') }} + {{ $t('monthly_payment') }} $1990 {{ $t('dollar') }}
        tr
          th {{ $t('pay') }}{{ $t('way') }}
          td
            permanently-pay-way(:contract_info="contract_info")
        tr(v-if="contract_info.paid_date !== null")
          th {{ $t('pay') }}{{ $t('date') }}
          td {{ contract_info.paid_date }}
        tr(v-if="contract_info.purchased !== null")
          th {{ $t('purchas') }}{{ $t('date') }}
          td {{ contract_info.purchased }}
        tr(v-if="contract_info.product_id && contract_info.product_id !== 0")
          th {{ $t('learn') }}{{ $t('terms') }}
          td
            read-learn-rule(:contract_info="contract_info")
</template>

<script type="text/javascript">
import i18n from './lang.js';
import ReadLearnRule from './../readLearnRule';
import PermanentlyPayWay from './permanentlyPayWay';
export default {
  name: 'VipPermanentlyContractInfo',

  components: {
    ReadLearnRule,
    PermanentlyPayWay,
  },

  props: {
    // eslint-disable-next-line
    contract_info: {
      type: Object,
      default: () => {
        return {
          product_name: '查無方案',
        };
      },
    },
    // eslint-disable-next-line
    vipAccountBaseProfile: {
      type: Object,
      default: () => {
        return {
          chinese_name: '中文姓名',
          english_name: 'English Name',
        };
      },
    },
  },

  i18n,

  data() {
    return {};
  },

  computed: {},

  watch: {},

  created() {},

  methods: {
    cutStartTime(time) {
      const showTime = time.substring(0, 10);
      return showTime + ' ' + this.$t('get_start');
    },
  },
};
</script>

<style lang="scss"></style>
