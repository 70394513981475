export default {
  messages: {
    tw: {
      serviceContent: '服務內容',
      classType: '課程種類',
      bookAndCancel: '訂課及取消方式',
      classRule: '使用規範',
      equipment: '設備規格',
    },
    en: {
      serviceContent: 'Service Content',
      classType: 'Class Type',
      bookAndCancel: 'Book And Cancel',
      classRule: 'Rule',
      equipment: 'Equipment',
    },
  },
};
