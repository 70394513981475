<template lang="pug">
div
  // 已綁訂
  template(v-if='profile.card')
    to-paid
  // 未綁訂
  template(v-else)
    bind-credit-card

</template>
<script type="text/javascript">
import ToPaid from './toPaid';
import BindCreditCard from './bindCreditCard';

export default {
  name: 'UnpaidSetting',

  components: {
    ToPaid,
    BindCreditCard,
  },

  props: {
    // eslint-disable-next-line
    profile: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },

  methods: {
  },
};
</script>
