var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "vip_transfer_and_share_apply_modal",
      "size": "lg",
      "effect": "fade",
      "ok-title": _vm.$t('agree'),
      "cancel-title": _vm.$t('disagree'),
      "title": _vm.$t('contract.share_detailed_info_apply_list')
    },
    on: {
      "ok": function ($event) {
        return _vm.submitConfirm(3);
      },
      "cancel": function ($event) {
        return _vm.submitConfirm(4);
      }
    }
  }, [_vm.contractInfo && _vm.contractInfo.vip_products_adjustment ? _c('div', [_c('tr', {
    staticClass: "row col-lg-12 mb-1"
  }, [_c('th', {
    staticClass: "col-lg-3"
  }, [_vm._v(_vm._s(_vm.$t('undertaker')))]), _c('td', {
    staticClass: "col-lg-3"
  }, [_vm._v(_vm._s(_vm.contractInfo.vip_products_adjustment.receive_vip_english_name))])]), _c('tr', {
    staticClass: "row col-lg-12 mb-1"
  }, [_c('th', {
    staticClass: "col-lg-3"
  }, [_vm._v(_vm._s(_vm.$t('transfer_out')) + _vm._s(_vm.$t('class.class_count')))]), _c('td', {
    staticClass: "col-lg-3"
  }, [_vm._v(_vm._s(_vm.contractInfo.vip_products_adjustment.amount) + " " + _vm._s(_vm.$t('class.class')))]), _c('th', {
    staticClass: "col-lg-3"
  }, [_vm._v(_vm._s(_vm.$t('transfer_out')) + _vm._s(_vm.$t('end_day')))]), _c('td', {
    staticClass: "col-lg-3"
  }, [_vm._v(_vm._s(_vm.contractInfo.vip_products_adjustment.month_amount) + " " + _vm._s(_vm.$t('month')) + " " + _vm._s(_vm.$t('contract.and')) + " " + _vm._s(_vm.contractInfo.vip_products_adjustment.day_amount) + " " + _vm._s(_vm.$t('contract.day')))])]), _c('tr', {
    staticClass: "row col-lg-12"
  }, [_c('th', {
    staticClass: "col-lg-3"
  }, [_vm._v(_vm._s(_vm.$t('undertaker')) + _vm._s(_vm.$t('start_date')))]), _c('td', {
    staticClass: "col-lg-3"
  }, [_vm._v(_vm._s(_vm.contractInfo.vip_products_adjustment.receive_contract_start))]), _c('th', {
    staticClass: "col-lg-3"
  }, [_vm._v(_vm._s(_vm.$t('undertaker')) + _vm._s(_vm.$t('end_date')))]), _c('td', {
    staticClass: "col-lg-3"
  }, [_vm._v(_vm._s(_vm.contractInfo.vip_products_adjustment.receive_contract_end))])]), _c('hr'), _c('tr', {
    staticClass: "row col-lg-12"
  }, [_c('th', {
    staticClass: "col-lg-12 mb-3"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t('precautions')) + ":")])]), _c('th', {
    staticClass: "col-lg-12 mb-4"
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('transfer_share_notice'))
    }
  })])])]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }