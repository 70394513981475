<template lang="pug">
div
  | {{ learning_time.learning_time }}
  br
  | ( {{ $t('total') }}{{ $t('class.class_count') }}: {{ learning_time.total_sessions }} | {{ $t('used') }}{{ $t('class.class_count') }}: {{ learning_time.used_sessions }} | {{ $t('class.number_of_remaining') }}: {{ learning_time.balance_sessions }} ) &nbsp;
  span.badge.badge-success.badge-pill(v-if='learning_time.status == 1') {{ $t('enable') }}
  span.badge.badge-danger.badge-pill(v-else-if='learning_time.status == 0') {{ $t('disable') }}
</template>
<script type="text/javascript">
export default {
  name: 'LearningTime',

  components: {},

  props: {
    // eslint-disable-next-line
    learning_time: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },

  data() {
    return {};
  },

  computed: {},

  watch: {},

  created() {},

  methods: {},
};
</script>
